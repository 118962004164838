const VIDEOS = [
  'https://www.youtube.com/watch?v=OmEyt2re96s',
  'https://www.youtube.com/watch?v=eiMNJtCWfAM',
  'https://www.youtube.com/watch?v=wRAF2UdAnYA',
  'https://www.youtube.com/watch?v=2P4VFg8vXsU',
  'https://www.youtube.com/watch?v=kOJlYCtx-5c',
  'https://www.youtube.com/watch?v=KyfvALwB7Bk',
  'https://www.youtube.com/watch?v=cMn2kgS9f3A',
  'https://www.youtube.com/watch?v=1PnsvMeRJbk',
  'https://www.youtube.com/watch?v=te1VeiqXB3g',
  'https://www.youtube.com/watch?v=jAwUSIRvH3w',
  'https://www.youtube.com/watch?v=OmEyt2re96s',
  'https://www.youtube.com/watch?v=eiMNJtCWfAM',
  'https://www.youtube.com/watch?v=wRAF2UdAnYA',
  'https://www.youtube.com/watch?v=2P4VFg8vXsU',
  'https://www.youtube.com/watch?v=kOJlYCtx-5c',
  'https://www.youtube.com/watch?v=KyfvALwB7Bk',
  'https://www.youtube.com/watch?v=cMn2kgS9f3A',
  'https://www.youtube.com/watch?v=1PnsvMeRJbk',
  'https://www.youtube.com/watch?v=te1VeiqXB3g',
  'https://www.youtube.com/watch?v=jAwUSIRvH3w',
  'https://www.youtube.com/watch?v=Hw-rmshAUv4',
  'https://www.youtube.com/watch?v=RRl4hhGhnIs',
  'https://www.youtube.com/watch?v=wTd_TpSO6j0',
  'https://www.youtube.com/watch?v=7KoDU-tOk4I',
  'https://www.youtube.com/watch?v=4eL8d6M1Keo',
  'https://www.youtube.com/watch?v=pqKHM_LnBGY',
  'https://www.youtube.com/watch?v=ToYecfVJWaE',
  'https://www.youtube.com/watch?v=H-_fb4Cj7KU',
  'https://www.youtube.com/watch?v=YuUhWnvrWao',
  'https://www.youtube.com/watch?v=Jy0GCuehcWQ',
  'https://www.youtube.com/watch?v=tizc6LCcGHw',
  'https://www.youtube.com/watch?v=ozf68Wra0IM',
  'https://www.youtube.com/watch?v=ShLvOOU2JsQ',
  'https://www.youtube.com/watch?v=WmQmDfP3vx0',
  'https://www.youtube.com/watch?v=iGREBu_Ktv0',
  'https://www.youtube.com/watch?v=Q3XYR7ohlYE',
  'https://www.youtube.com/watch?v=aWapbOifYfM',
  'https://www.youtube.com/watch?v=674pnBTZdNQ',
  'https://www.youtube.com/watch?v=w6bzFYTdZ6E',
  'https://www.youtube.com/watch?v=JwXvr8qk5JA',
  'https://www.youtube.com/watch?v=ZK9ZrUanGy4',
  'https://www.youtube.com/watch?v=coEfUQGFwVw',
  'https://www.youtube.com/watch?v=JR-S-L-fOEQ',
  'https://www.youtube.com/watch?v=zt7ILJNpcmU',
  'https://www.youtube.com/watch?v=NstzUu6VFnA',
  'https://www.youtube.com/watch?v=-3tt_W80FOU',
  'https://www.youtube.com/watch?v=AufAcqiaLhE',
  'https://www.youtube.com/watch?v=uG9l4pBl7aU',
  'https://www.youtube.com/watch?v=m4QJ7OYJfGc',
  'https://www.youtube.com/watch?v=hlWNnxyHc4k',
  'https://www.youtube.com/watch?v=HAZqJn75Ldc',
  'https://www.youtube.com/watch?v=GO-ujK0aULM',
  'https://www.youtube.com/watch?v=1Gw9FemfP28',
  'https://www.youtube.com/watch?v=nUeOqdyCN2M',
  'https://www.youtube.com/watch?v=Bn7bifS6PUs',
  'https://www.youtube.com/watch?v=7y5DoSYt1IQ',
  'https://www.youtube.com/watch?v=mKs4Pkeq8wo',
  'https://www.youtube.com/watch?v=vPwmDWtmeYQ',
  'https://www.youtube.com/watch?v=HDsYp3GX0_s',
  'https://www.youtube.com/watch?v=tVqez8vaR_8',
];

export default VIDEOS.map((video) => ({
  model_type: 'film',
  video_code: video,
  title: '',
  announce: '',
}));
